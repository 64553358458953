<template>
  <div class="">
    <el-form :inline="true">
      <el-form-item label="审核状态">
        <el-select v-model="status" placeholder="状态">
          <el-option
            v-for="(item, index) in status_list"
            :key="index"
            :label="item.label"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
      ref="multipleTable"
      :data="list"
      style="width: 100%"
      tooltip-effect="dark"
    >
      <el-table-column prop="user_name" label="接受用户"> </el-table-column>
      <el-table-column prop="sned_name" label="申请人"> </el-table-column>
      <el-table-column prop="cousume" label="发放数量"> </el-table-column>
      <el-table-column prop="create_time" label="申请时间"> </el-table-column>
      <el-table-column prop="remark" label="备注"> </el-table-column>
      <el-table-column prop="remark" label="状态">
        <template slot-scope="{ row }">
          <p v-if="row.status == 0">待审核</p>
          <p v-if="row.status == 1">同意</p>
          <p v-if="row.status == 2">拒绝</p>
        </template>
    </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="{ row }" >
          <div class="flex"  v-permission="'consumeSendCheck'">
            <el-button v-if="row.status == 0" type="success" @click="update(row, 1)">同意</el-button>
            <el-button v-if="row.status == 0" type="warning" @click="update(row, 2)">拒绝</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <paging-fy
      @currentPageChange="fyChange"
      :currentPage="currentPage"
      :total="total"
    >
    </paging-fy>
  </div>
</template>
      
    <script>
export default {
  data() {
    return {
      list: [],
      adddialogVisible: false,
      ReleaseAmount: "",
      user_id: "",
      remark: "",
      end_time: "",
      currentPage: 1,
      total: 0,
      mobile: "",
      status: 0,
      status_list: [
        {
          label: "待审核",
          name: 0,
        },
        {
          label: "通过",
          name: 1,
        },
        {
          label: "拒绝",
          name: 2,
        },
      ],
    };
  },

  mounted() {
    this.getList();
  },
  methods: {
    update(row, v) {
      this.$confirm(`确认审核${v === 1 ? "通过" : "拒绝"}消费金发放吗？`)
        .then(()=>{
           this.updateConsumeRecode(row,v)
        })
        .catch(()=>{
            // this.updateConsumeRecode(row,v)
        });
    },
    updateConsumeRecode(row, v) {
      this.$post("/user/consumeSendRecord/update", {
        id: row.id,
        status: v,
      }).then((res) => {
        if (res.data.code == 0) {
            this.getList();
            this.$message('审核成功')
        }else{
            this.$message(res.data.message)
        }
      });
    },
    search(e) {
      this.currentPage = 1;
      this.list = [];
      this.getList();
    },
    fyChange(e) {
      this.currentPage = e;
      this.getList();
    },
    getList() {
      this.$get("user/consumeSendRecord/queryManagerListPage", {
        currentPage: this.currentPage,
        pageSize: 10,
        status: this.status,
      }).then((res) => {
        if (res.data.code == 0) {
          this.list = res.data.data.rows;
          this.total = res.data.data.total;
        }
      });
    },
  },
};
</script>
      
    <style lang="less" scoped>
</style>