<template>
  <div id="consumerCoupon">
    <el-row style="background-color: #ffffff">
      <content-title :nav="nav"></content-title>
    </el-row>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="生成列表" name="0">
           <consumeOrderList></consumeOrderList>    
      </el-tab-pane>
      <el-tab-pane label="赠送列表" name="1">
           <consumeGiveList></consumeGiveList>
      </el-tab-pane>
      <el-tab-pane label="消费金发放审核" v-if="permissionList.includes('consumeSendCheck')" name="2">
          <ConsumeSendCheck></ConsumeSendCheck>
      </el-tab-pane>
      
    </el-tabs>
  </div>
</template>

<script>
import consumeOrderList from "./components/consume/consumeOrderList.vue";
import consumeGiveList from "./components/consume/consumeGiveList.vue";
import ConsumeSendCheck from "./components/consume/consumeSendCheck.vue";
export default {
  components: {
    consumeOrderList,
    consumeGiveList,
    ConsumeSendCheck
  },
  data() {
    return {
      nav: { firstNav: "卡券管理中心", secondNav: "消费金管理" },
      activeName: 0,
    }
  },
  computed:{
    permissionList(){
      return this.$store.state.permissionList
    }
  },
  methods: {
      handleClick() {},
  }
};
</script>

<style lang="less">
#consumerCoupon {
  background-color: #ffffff;
  padding: 0 20px;
  .an-box {
    display: flex;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 30px;
    flex-wrap: wrap;
    .an-p {
      color: #ff7e00;
      border-radius: 5px;
      border: #ff7e00 1px solid;
      padding: 4px 8px;
      margin-right: 20px;
      height: 24px;
      line-height: 24px;
      @media screen and(max-width:768px) {
        margin-bottom: 20px;
      }
    }
  }
}
.el-tabs__item {
  font-size: 16px !important;
}
.cell {
  text-align: center;
}
</style>
