<template>
  <div class="">
    <el-form :inline="true" >
      <el-form-item label="手机号">
        <user-select :userid.sync = "user_id"></user-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
      ref="multipleTable"
      :data="list"
      style="width: 100%"
      tooltip-effect="dark"
    >
      <el-table-column prop="nick_name" label="用户名称"> </el-table-column>
      <el-table-column prop="mobile" label="用户手机号"> </el-table-column>
      <el-table-column prop="amount" label="赠送数量"> </el-table-column>
      <el-table-column prop="create_time" label="赠送时间"> </el-table-column>
      <el-table-column prop="remark" label="备注"> </el-table-column>
    </el-table>
    <paging-fy
      @currentPageChange="fyChange"
      :currentPage="currentPage"
      :total="total"
    >
    </paging-fy>
  </div>
</template>
    
  <script>
export default {
  data() {
    return {
      list: [],
      adddialogVisible: false,
      ReleaseAmount: "",
      user_id: "",
      remark: "",
      end_time: "",
      currentPage: 1,
      total: 0,
      mobile: "",
    };
  },

  mounted() {
    this.getList();
  },
  methods: {
    search(e) {
      this.currentPage = 1;
      this.list = [];
      this.getList();
    },
    fyChange(e) {
      this.currentPage = e;
      this.getList();
    },
    getList() {
      this.$get("user/consumeRecord/queryManagerListPage", {
        currentPage: this.currentPage,
        pageSize: 10,
        type: 5,
        user_id: this.user_id || null,
      }).then((res) => {
        if (res.data.code == 0) {
          this.list = res.data.data.rows;
          this.total = res.data.data.total;
        }
      });
    },
  },
};
</script>
    
  <style lang="less" scoped>
</style>