<template>
  <div class="">
    <el-form abel-position="right">
      <el-form-item>
        <el-button v-permission="'sendConsume'" type="warning" @click="adddialogVisible = true"
          >发放消费金</el-button
        >
      </el-form-item>
      <el-form-item label="类型">
        <el-select v-model="Gradevl" placeholder="请选择" @change="Grad">
          <el-option
            v-for="item in Grade"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-table
      ref="multipleTable"
      :data="list"
      style="width: 100%"
      tooltip-effect="dark"
    >
      <el-table-column prop="nick_name" label="用户名称"> </el-table-column>
      <el-table-column prop="mobile" label="用户手机号"> </el-table-column>
      <el-table-column prop="create_time" label="发放时间"> </el-table-column>
      <el-table-column prop="expire_time" label="到期时间"> </el-table-column>
      <el-table-column prop="amount" label="金额"> </el-table-column>
      <el-table-column prop="remark" label="备注"> </el-table-column>
    </el-table>
    <paging-fy
      @currentPageChange="fyChange"
      :currentPage="currentPage"
      :total="total"
    >
    </paging-fy>
    <el-dialog title="发放消费金" :visible.sync="adddialogVisible" width="30%">
      <div>
        <el-form
          class="demo-form-inline"
          label-width="100px"
          label-position="left"
        >
          <el-form-item label="发放用户" required>
            <user-select :userid.sync="user_id" @change="changeUser"></user-select>
          </el-form-item>
          <el-form-item label="发放金额" required>
            <el-input
              v-model="ReleaseAmount"
              placeholder="发放消费金金额"
            ></el-input>
          </el-form-item>
          <el-form-item label="过期时间" required>
            <el-date-picker
              v-model="end_time"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="发放说明">
            <el-input
              type="textarea"
              v-model="remark"
              placeholder="请说明发放的原因"
            ></el-input>
            <!-- <div class="flex">
              <div class="tips" @click="remark = '官方活动发放'">
                官方活动发放
              </div>
            </div> -->
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="adddialogVisible = false">取 消</el-button>
        <el-button
          style="background-color: #ff7e00; color: #ffffff"
          type="primary"
          @click="GrantAn"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
    
  <script>
export default {
  data() {
    return {
      list: [],
      adddialogVisible: false,
      ReleaseAmount: "",
      user_id: "",
      remark: "",
      end_time: "",
      currentPage: 1,
      total: 0,
      mobile: 0,
      user:{},
      Gradevl: "系统赠送",
      Grade: [
        {
          name: "买卡赠送",
          id: 1,
        },
        {
          name: "充值",
          id: 2,
        },
        {
          name: "消费",
          id: 3,
        },
        {
          name: "系统赠送",
          id: 4,
        },
      ],
    };
  },

  mounted() {
    if (JSON.parse(localStorage.getItem("managerInfo"))) {
      if (
        JSON.parse(localStorage.getItem("managerInfo")).mobile == "18658876977"
      ) {
        this.mobile = 1;
      }
    }
    this.getList();
  },
  computed:{
    permissionList(){
      return this.$store.state.permissionList
    }
  },
  methods: {
    changeUser(v){
      this.user = v
    },

    Grad(e) {
      this.currentPage = 1;
      this.getList(e);
    },
    fyChange(e) {
      this.currentPage = e;
      this.getList();
    },
    GrantAn() {
      if (this.user_id && this.ReleaseAmount && this.end_time) {
        let manager = JSON.parse(localStorage.getItem('managerInfo')) 
        this.$post("user/consumeSendRecord/insert", {
          user_id: this.user.user_id,
          user_name: this.user.nick_name,
          send_id: manager.id,
          send_name: manager.name,
          consume: this.ReleaseAmount,
          expire_time: this.end_time + " 23:59:59",
          remark: this.remark || "系统发放",
        }).then((res) => {
          if (res.data.code == 0) {
            this.getList();
            this.adddialogVisible = false;
            this.$message({
              showClose: true,
              message: "已发放",
              type: "success",
            });
          } else {
            this.adddialogVisible = false;
            this.$message({
              showClose: true,
              message: res.data.message,
              type: "error",
            });
          }
        });
      } else {
        this.$message({
          showClose: true,
          message: "请补全信息",
          type: "error",
        });
      }
    },
    getList(e) {
      this.$get("user/consumeRecord/queryManagerListPage", {
        currentPage: this.currentPage,
        pageSize: 10,
        type: e || 4,
      }).then((res) => {
        if (res.data.code == 0) {
          this.list = res.data.data.rows;
          this.total = res.data.data.total;
        }
      });
    },
  },
};
</script>
    
  <style lang="less" scoped>
</style>